(function() {
    'use strict';

    function init() {

        // INLINE SVG
        jQuery('img.svg').each(function(i) {
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                var $svg = jQuery(data).find('svg');
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }
                $svg = $svg.removeAttr('xmlns:a');
                $img.replaceWith($svg);
            }, 'xml');
        });


        setTimeout(mainLayout, 100);
        setTimeout(animation, 100);
        setTimeout(slider, 100);
        setTimeout(func, 100);

        $(window).scroll(function() {
            setTimeout(function() {
                animation();
            }, 300)
        });

    }
    init(); // end of init()

    $(window).resize(function() {
        setTimeout(mainLayout, 100);
        setTimeout(slider, 100);
    });

    function mainLayout() {
        var h = $('#header').outerHeight(true),
            m = $('main'),
            f = $('#footer').outerHeight(true),
            set = f + h;

        m.css('min-height', 'calc(100vh - ' + set + 'px)');
    }

    function animation() {
        $(".animate").each(function() {
            var bottom_of_object = $(this).offset().top + 10;
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            if (bottom_of_window > bottom_of_object) {
                $(this).addClass('animate--in');
            }
        })
    }

    function slider() {
        $('.scompany__slider').each(function() {
            var slider = $(this),
                item = slider.find('.slider-item');

            if (item.length > 3) {
                slider.owlCarousel({
                    items: 3,
                    loop: true,
                    dots: true,
                    nav: false,
                    autoplay: true,
                    autoplayTimeout: 6000,
                    autoplaySpeed: 800,
                    margin: 100,
                });
            } else {
                slider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
                slider.find('.owl-stage-outer').children().unwrap();
            }
        })
    }
    slider();

    function uploadImages(){
        //inputfile
        $('.upload-file').each(function(e) {
            var t = $(this),
                input = t.find('.inputfile'),
                label = t.find('.label-btn'),
                del = t.find('.del-btn'),
                info = t.find('.file-info'),
                prev = t.find('.image-preview'),
                pB = t.find('.pB'),
                to = t.closest('form').attr('action'),
                fSize;

            function toggleDel() {
                if (t.hasClass('has-file')) {
                    del.removeClass('dis');
                } else {
                    del.addClass('dis');
                }
            }
            toggleDel();

            function readURL(input) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = function(e) {
                        lb();
                        setTimeout(
                        function()
                        {
                            prev.css('background-image', 'url(' + e.target.result + ')');
                        }, 1000);
                        // console.log('hai');
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            }

            function lb(){
                var ajax = new XMLHttpRequest();
                var formdata = new FormData();
                // ajax.upload.addEventListener('progress', uploadProgress, false);
                ajax.onprogress = function (e) {
                    if (e.lengthComputable) {
                        // console.log(e.loaded+  " / " + e.total);
                        var percent = (e.loaded / e.total) * 100;
                        pB.attr('value', Math.round(percent));
                    }
                }

                ajax.onloadstart = function (e) {
                    pB.removeClass('hide');
                    console.log(to);
                }
                ajax.onloadend = function (e) {
                    pB.addClass('hide');
                }
                ajax.open("POST", to);
                ajax.send(FormData);

            }

            input.change(function(e) {
                var fileName = '',
                    val = $(this).val();

                if (this.files && this.files.length > 1) {
                    fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                } else if (e.target.value) {
                    fileName = e.target.value.split('\\').pop();
                }

                if (this.files[0].size > 200097152) {
                    // alert('Max upload 2MB.');
                    alertUpload('Your file is too large!');
                    // input.val('');
                } else {

                    if (fileName && prev.length == 0) {
                        switch (val.substring(val.lastIndexOf('.') + 1).toLowerCase()) {
                            case 'doc':
                            case 'docx':
                            case 'pdf':
                            case 'txt':
                            case 'jpg':
                            case 'png':
                                info.html(fileName);
                                info.removeClass('deleted');
                                t.addClass('has-file');
                                break;
                            default:
                                // alert('Only document files are allowed.')
                                alertUpload('Only document files are allowed.');
                                break;
                        }
                    }

                    if (prev.length != 0) {
                        switch (val.substring(val.lastIndexOf('.') + 1).toLowerCase()) {
                            case 'gif':
                            case 'jpg':
                            case 'png':
                            case 'svg':
                                readURL(this);
                                t.addClass('has-file');
                                break;
                            default:
                                // alert('Only image files are allowed.')
                                alertUpload('Only image files are allowed.');
                                break;
                        }
                    }

                }

                toggleDel();
            });

            del.click(function() {
                console.log('a');
                if (prev.length != 0) {
                    prev.css('background-image', '');
                }

                info.addClass('deleted');
                input.val('');
                t.removeClass('has-file');

                pB.addClass('hide');
                pB.attr('value', 0)

                toggleDel();
            })
        });
    }uploadImages();

    $('select.select').selectpicker();

    function func() {

        $('a[target!="_blank"]')
            .not('[href*="#"]')
            .not('.scroll-to')
            .not('[data-lity]')
            .not('[data-product]')
            .not('.lsb-preview').click(function(t) {
                t.preventDefault();
                var href = this.href;
                $("body").addClass("link-transition");
                setTimeout(function() {
                    window.location = href
                }, 500)
            })

        $("body").addClass("load-page");
        $("html, body").animate({ scrollTop: 0 }, 100);

        // STICKY HEADER
        if ($('.header').length > 0) {
            var header = $('.header'),
                pos = 122;
            $(window).on('scroll', function() {
                var scroll = $(window).scrollTop();
                if (scroll >= pos) {
                    header.addClass('sticky');
                    $('body').addClass('header-stick');
                } else {
                    header.removeClass('sticky');
                    $('body').removeClass('header-stick');
                }
            });
        }

        $('.header-toggle').click(function() {
            $('body').toggleClass('menu-open');
        })

        $('.has-sub').each(function() {
            var t = $(this);
            $('.has-sub').click(function() {
                t.toggleClass('sub-open');
                $('.has-sub').not(this).removeClass('sub-open');
            })
        })

        $('.scroll-down').each(function() {
            var target = $(this).data('target');
            $(this).click(function() {
                $('html, body').animate({
                    scrollTop: $(target).offset().top - 100
                }, 900);
            })
        })

        // SMOOTH SCROLL
        $('.scroll-to').click(function(event) {
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 60
                    }, 800, function() {
                        var $target = $(target);
                        if ($target.is(":focus")) {
                            return false;
                        } else {
                            $target.attr('tabindex', '-1');
                        };
                    });
                }
            }
        });

    } // end of func

    $('.modal').on('show.bs.modal', function(e) {
        $('html').addClass('modal-open');
        $('body').removeClass('menu-open');
    })

    $('.modal').on('hide.bs.modal', function(e) {
        $('html').removeClass('modal-open');
    })


})();
